import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import { ProcessLog } from '@/found/modules';
import Form from '../form';
import DetailForm from '../form/detail.vue';
import ChangeForm from '../form/form-change';
import AdjustmentForm from '../form/form-edit-adjust';

export default {
  name: 'fee_rule_list',
  extends: TablePage,
  data() {
    return {
      params: {
        feeBudgetType: 'input',
      },

      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    DetailForm,
    ChangeForm,
    AdjustmentForm,
    ProcessLog,
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { ext31 } = row;
      if (ext31 && code === 'submit_approval' && (ext31 !== 'create' && ext31 !== 'rejected')) {
        return false;
      }
      if (ext31 && code === 'edit' && (ext31 !== 'create' && ext31 !== 'rejected')) {
        return false;
      }
      if (ext31 && (code === 'adjustment' || code === 'change' || code === 'detail') && (ext31 !== 'approved')) {
        return false;
      }
      if (code === 'process_log' && !row.ext32) {
        return false;
      }
      return true;
    },
    // 按钮点击事件
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (val.code === 'detail') {
        this.formName = 'DetailForm';
        this.modalConfig = {
          title: '明细',
          width: '80%',
        };

        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
          feeBudgetCode: row.feeBudgetCode,
        };

        this.openDrawer();
      } else if (val.code === 'change') {
        this.formName = 'ChangeForm';
        this.modalConfig = {
          title: '变更',
          width: '60%',
        };
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openDrawer();
      } else if (val.code === 'adjustment') {
        this.formName = 'AdjustmentForm';
        this.modalConfig = {
          title: '调整',
          width: '60%',
        };
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openDrawer();
      } else if (val.code === 'submit_approval') {
        request.get('/tpm/tpmFeeBudgetController/query', {
          id: row.id,
          feeBudgetType: 'input',
        })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              rowData.saveType = '5';
              this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmFeeBudgetExt/audit' });
            }
          });
      } else if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.ext32 },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 提交审批回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };
      this.$emit('approve', configParams);
    },
  },
  created() {
    this.getConfigList('pre_investment_list');
  },

};
